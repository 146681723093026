import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import BackgroundImage from "gatsby-background-image"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { FaAngleDoubleLeft } from "react-icons/fa"
// import { TiStar } from "react-icons/ti"
// import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

// import Container from "react-bootstrap/Container"
// import Carousel from "react-bootstrap/Carousel"
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'

import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import ServiceCard from "../components/CardService"
import FormService from "../components/Global/FormService"
import ContentfulReachContent from "../components/Global/ContentfulReachContent"
import Steps from "../components/steps"
import StepsMed from "../components/stepsMed"
import FAQList from "../components/faq"
import SEO from "../components/Global/MetaHead"
import ModalConsult from "../components/Global/ModalConsult"
import CallMeBack from "../components/callback"
// import CardMember from "../components/CardMember"
// import CardDeck from "react-bootstrap/CardDeck"
import Testimonials from "../components/testimoonials"
import SectionMembers from "../components/SectionMember"
import PhoneFloat from "../components/PhoneFloatButton"
import ViberFloat from "../components/ViberFloat"

export const query = graphql`
  query($slug: String!) {
    testimonials: allContentfulTestimonials(
      filter: {
        service: { seo: { slug: { eq: $slug } } }
        node_locale: { eq: "uk" }
      }
    ) {
      nodes {
        clientPhoto {
          fixed(width: 50) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        screen {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 400) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
        date(locale: "uk", fromNow: true)
        id
        url
        stars
        clienName
        service {
          id
          title
          serviceId
        }
        text {
          text
        }
      }
      totalCount
    }
    service: contentfulService(
      seo: { slug: { eq: $slug }, node_locale: { eq: "uk" } }
    ) {
      paymentRules
      managers {
        id
        name
        phone
        viber
        description
        binotelClass
        photo {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      recomend {
        id
        title
        price
        termFrom
        termUpTo
        validity
        taxAmount
        serviceId
        seo {
          title
          slug
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
            internal {
              content
            }
          }
          metaImage {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      serviceId
      title
      id
      price
      ltdPrice
      priceItems {
        id
        name
        price
        daysFrom
        daysUpTo
        isMinimalPrice
      }
      termFrom
      termUpTo
      validity
      taxAmount
      seo {
        title
        slug
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
          internal {
            content
          }
        }
        metaImage {
          fluid(maxWidth: 1024) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 620, height: 460) {
            src
          }
        }
      }
      content {
        json
      }
      teaser {
        teaser
      }
      department {
        id
        name
        nameFrom
      }
      requiredDocumentsFop {
        id
        document
      }
      requiredDocumentsTov {
        id
        document
      }
      addPrice {
        price
        name
        priceType
        id
      }
      faq {
        id
        faqQuestion {
          internal {
            content
          }
        }
        faqAnswer {
          id
          json
        }
        childContentfulFaqFaqAnswerRichTextNode {
          json
        }
      }
    }
  }
`

const ServiceTemplate = ({ data: { service, testimonials } }) => {
  const backgroundFluidLinearGradient = [
    service.seo.metaImage.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35,65,97,1) 80%)`,
  ].reverse()

  return (
    <>
      <SEO
        title={service.seo.title}
        description={service.seo.description.internal.content}
        image={`https:${service.seo.metaImage.fixed.src}`}
        url={`/${service.seo.slug}/`}
        lang="uk"
      />
      <Header serviceId={service.serviceId} />
      <BackgroundImage
        fluid={backgroundFluidLinearGradient}
        className="firstscreen"
      >
        <div
          className="service-first-screen jumbotron jumbotron-fluid"
          itemScope=""
          itemType="http://schema.org/Service"
        >
          <meta itemProp="serviceType" content="Оформлення документів" />
          <div className="container">
            <div className="row">
              <h1 className="pb-5 text-center w-100" itemProp="name">
                {service.title}
              </h1>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6 pb-4 pb-md-0">
                <div className="contact-form pt-5 px-4 px-sm-5 needs-validation">
                  <div className="title2 mb-3">Потрібна допомога?</div>
                  <FormService
                    slug={service.seo.slug}
                    title={service.title}
                    price={service.price}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 product-text">
                {service.teaser.teaser ? (
                  <p itemProp="description">{service.teaser.teaser}</p>
                ) : (
                  ""
                )}

                {service.department && service.serviceId !== 8 ? (
                  <p itemProp="description">
                    {`Офіційне оформлення від ${service.department.nameFrom} для фізичних або юридичних осіб.`}
                  </p>
                ) : (
                  ""
                )}

                <div className="row mt-2">
                  {/* <div className="col-sm-12 product-options pb-3">
                    Ціна&nbsp;
                    <span itemProp="price" className="price-main">
                      100
                    </span>
                    <meta itemProp="priceCurrency" content="UAH" />
                  </div> */}

                  {service.priceItems.map(priceitem => (
                    <div
                      key={priceitem.id}
                      className="col-sm-12 product-options"
                      itemScope=""
                      itemProp="offers"
                      itemType="http://schema.org/Offer"
                    >
                      <span itemProp="name">{priceitem.name}</span>
                      {priceitem.isMinimalPrice ? " від " : " — "}
                      <span itemProp="price">{priceitem.price}</span>
                      <meta itemProp="priceCurrency" content="UAH" /> грн
                    </div>
                  ))}

                  {/* {service.ltdPrice > 0 || service.ltdPrice ? (
                    <div className="col-sm-12 product-options">
                      Ціна для юр. осіб — {service.ltdPrice} грн
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* {service.taxAmount > 0 ? (
                    <div className="col-sm-12 product-options">
                      Платіж державі — {service.taxAmount} грн
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* Terms of registration */}
                  {service.termFrom || service.termUpTo ? (
                    <div className="col-sm-12 product-options mt-3">
                      Оформлення —
                      {service.termFrom > 0 ? ` від ${service.termFrom} ` : ""}
                      {service.termUpTo > 0
                        ? ` до ${service.termUpTo}`
                        : ""}{" "}
                      днів
                    </div>
                  ) : null}
                  <div className="col-sm-12 product-options">
                    Термін дії — <b>{service.validity}</b>
                  </div>

                  {service.paymentRules ? (
                    <div className="col-sm-12 product-options mb-4 mb-md-0">
                      {service.paymentRules}
                    </div>
                  ) : (
                    ``
                  )}
                </div>
                {/* end of Schema.Service */}
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {service.requiredDocumentsFop || service.requiredDocumentsTov ? (
        <section className="documents container py-5">
          <div className="title2 text-uppercase pb-5">
            Що потрібно для оформлення?
          </div>
          <div className="row">
            <div className="col pb-4 pb-md-0">
              <div className="card">
                <div className="card-header">Перелік документів для ФОП</div>
                <div className="card-body">
                  <ol className="documents-list">
                    {service.requiredDocumentsFop.map(doc => (
                      <li key={doc.id}>{doc.document};</li>
                    ))}
                  </ol>
                  <ModalConsult
                    buttonText={`Почати оформлення`}
                    buttonVariant={`link`}
                    buttonClass={`licenselink`}
                    slug={service.seo.slug}
                    title={service.seo.title}
                    price={service.price}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-header">
                  Перелік документів для юр. осіб
                </div>
                <div className="card-body">
                  <ol className="documents-list">
                    {service.requiredDocumentsTov.map(doc => (
                      <li key={doc.id}>{doc.document};</li>
                    ))}
                  </ol>
                  <ModalConsult
                    buttonText={`Почати оформлення`}
                    buttonVariant={`link`}
                    buttonClass={`licenselink`}
                    slug={service.seo.slug}
                    title={`Юр ${service.seo.title}`}
                    price={service.price}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ``
      )}
      {service.addPrice ? (
        <section className="additional py-5">
          <div className="container">
            <div className="title2 text-uppercase py-5">
              Що сплачується окремо?
            </div>
            <div className="row d-flex justify-content-center align-items-center">
              <ul className="col-sm-12 col-xs-12 col-md-6">
                {service.addPrice.map(option => (
                  <li className="mb-5" key={option.id}>
                    {option.priceType} {option.price} грн — {option.name};
                  </li>
                ))}
              </ul>
              <div className="d-none d-sm-block d-md-block point-left">
                <FaAngleDoubleLeft />
              </div>
            </div>
          </div>
        </section>
      ) : (
        ``
      )}
      {service.content ? (
        <section className="chip py-5">
          <div className="container">
            {/* {documentToReactComponents()} */}
            <ContentfulReachContent contentJSON={service.content.json} />
          </div>
        </section>
      ) : (
        ``
      )}
      <SectionMembers lang="uk" managers={service.managers} />
      {/* <Container as="section" className="contact-member-section">
        <div className="title2 py-5 text-uppercase h3">
          Кому задати питання?
        </div>
        <CardDeck className="managers text-white justify-content-center">
          {service.managers.map(manager => {
            return (
              <CardMember
                key={manager.id}
                fluid={manager.photo.fluid}
                name={manager.name}
                description={manager.description}
                phone={manager.phone}
                viber={manager.viber}
              />
            )
          })}
        </CardDeck>
      </Container> */}
      {testimonials.totalCount > 0 ? (
        <Testimonials testimonials={testimonials.nodes} />
      ) : (
        ``
      )}
      {service.faq ? <FAQList faqQL={service.faq} /> : ``}
      {/* Show another StepsComponent at Medpraktika page */}
      {service.serviceId === 9 ? <StepsMed /> : <Steps />}
      {service.recomend ? (
        <section className="relatedservices container py-5">
          <div className="title2 text-uppercase pb-5">Супутні послуги</div>
          <div className="card-columns">
            {service.recomend.map(recomendation => (
              <ServiceCard
                key={recomendation.id}
                serviceTitle={recomendation.title}
                servicePrice={recomendation.price}
                serviceValid={recomendation.validity}
                serviceFrom={recomendation.termFrom}
                serviceUpTo={recomendation.termUpTo}
                serviceTax={recomendation.taxAmount}
                serviceLink={`/${recomendation.seo.slug}/`}
                serviceFluidImage={recomendation.seo.metaImage.fluid}
                serviceId={recomendation.serviceId}
              />
            ))}
          </div>
        </section>
      ) : (
        ``
      )}
      <CallMeBack
        slug={service.seo.slug}
        title={service.seo.title}
        price={service.price}
      />
      <Footer
        slug={`/${service.seo.slug}/`}
        viber={service.managers[0].viber}
        phones={service.managers[0].phone}
      />
      <PhoneFloat phones={service.managers[0].phone} />
      <ViberFloat viber={service.managers[0].viber} />
    </>
  )
}

export default ServiceTemplate

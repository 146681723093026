import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { RiMailSendLine } from "react-icons/ri"
import { HiOutlineDocumentDuplicate } from "react-icons/hi"
import { BsPersonCheck } from "react-icons/bs"

import { FaViber } from "react-icons/fa"

const Steps = () => {
  const data = useStaticQuery(graphql`
    query {
      one: contentfulAsset(
        file: { fileName: { eq: "alexandre-boucher-CoUogz3Udak-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      two: contentfulAsset(
        file: { fileName: { eq: "scott-graham-OQMZwNd3ThU-unsplash.jpg" } }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      three: contentfulAsset(
        file: {
          fileName: { eq: "samuele-errico-piccarini-qAc3UNF8Hm4-unsplash.jpg" }
        }
      ) {
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `)
  const backgroundFluidOne = [
    data.one.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidTwo = [
    data.two.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  const backgroundFluidThree = [
    data.three.fluid,
    `linear-gradient(
      25deg, 
      rgba(128,0,128,0.2),
      rgba(35, 65, 97,1) 80%)`,
  ].reverse()

  return (
    <section className="steps container py-5">
      <div className="title2 py-5 text-uppercase">Як працює центр?</div>
      <div className="row">
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidOne}>
            <RiMailSendLine />
          </BackgroundImage>
          <p className="step-title">Відправте документи</p>
          <p className="step-description grayscale-hover">
            Отримайте безкоштовну консультацію, надішліть нам скани або фото
            документів на аудит у&nbsp;
            <FaViber /> Viber, або електронну пошту licenzii.ukr@gmail.com.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidTwo}>
            <HiOutlineDocumentDuplicate />
          </BackgroundImage>
          <p className="step-title">Готуємо ліцензійну справу</p>
          <p className="step-description grayscale-hover">
            Ми заповнюємо документи та відомості про стан матеріально-технічної
            бази за 3-5 днів, узгоджуємо з вами для подачі, та за вашим підписом
            подаємо до єдиного вікна МОЗ.
          </p>
        </div>
        <div className="col-sm-12 col-lg-4 pb-4 pb-md-0">
          <BackgroundImage className="topicon" fluid={backgroundFluidThree}>
            <BsPersonCheck />
          </BackgroundImage>
          <p className="step-title">Отримайте ліцензію і працюйте</p>
          <p className="step-description grayscale-hover">
            Після позитивного рішення МОЗ сплачуєте послуги Центру Ліцензій та
            державний платіж в розмірі одного прожиткового мінімуму (2684 грн на
            січень 2023).
          </p>
        </div>
      </div>
    </section>
  )
}

export default Steps
